<template>
  <div class="login__wrap">
    <div class="login__head">
      <el-image class="logoImg" :src="require('@/assets/img/logo.png')"></el-image>
    </div>
    <div class="login__bg">
      <div class="login__main">
        <div class="login__box">
          <el-form
            class="sign__main"
            ref="elForm"
            :model="form.data"
            :rules="form.rules"
            @submit.prevent="submit"
          >
            <div class="title">手机号登录-需求方</div>
            <el-form-item prop="account">
              <el-input v-model.trim="form.data.account" clearable placeholder="请输入账号" prefix-icon="el-icon-user" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-row :gutter="15">
                <el-col :span="15">
                  <el-input
                    v-model="form.data.code"
                    clearable
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
                    maxlength="6"
                  ></el-input>
                </el-col>
                <el-col :span="9">
                  <el-button
                    style="width: 100%"
                    type="primary"
                    :loading="form.codeLoading && !code.interval"
                    :disabled="form.codeLoading || !!code.interval"
                    @click="getCode"
                  >
                    {{ code.interval ? `${code.step}s` : "获取验证码" }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- <el-form-item prop="password">
              <el-input v-model.trim="form.data.password" clearable show-password placeholder="请输入密码" prefix-icon="el-icon-key"></el-input>
            </el-form-item> -->
            
            <el-form-item style="margin-top: 50px;">
              <el-button class="theneBtn flash-hover" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
            </el-form-item>
          </el-form>
          <div class="rule">
            登录即代表同意
            <a href="http://static.cuntoubao.cn/xieyi/" target="_blank">《用户协议》</a>和
            <a href="http://static.cuntoubao.cn/wuyoukong/yinsi.html" target="_blank">《隐私条款》</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="foot__wrap">
      <div class="page-width">
        <div class="flex-row group_13 space-y-16">
          <div class="flex-row items-start space-x-38">
            <img
              class="image_8" :src="require('@/assets/img/logo.png')"/>
          </div>
          <div class="copyright">
            <div class="text_12">人工咨询热线：0791-87581098</div>
            <div class="text_12"> Copyright © 2023江西金手指科技有限公司 All Rights Reserved</div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank" style="text-decoration:none;">
              <div class="text_12">
                赣ICP备2022002402号-1
              </div>
            </a>
          </div>
        </div>
        <div class="flex-row group_12 space-x-44">
          <div class="code-box"><img style="width:100%" :src="require('@/assets/img/code-ios.png')" /></div>
          <div class="code-box"><img style="width:100%" :src="require('@/assets/img/code-android.png')" /></div>
          <div class="code-box"><img style="width:100%" :src="require('@/assets/img/code.png')" /></div>
        </div>
      </div>
      <div class="flex-row justify-end space-x-34-reverse" style="width:1320px;margin:0 auto;margin-top:10px;">
        <div><span class="font_14" style="margin-right:20px">下载苹果APP</span></div>
        <div><span class="font_14" style="margin-right:12px">下载安卓APP</span></div>
        <div><span class="font_14" style="margin-right:8px">扫码使用小程序</span></div>
      </div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
export default {
  name: 'Login',
  data() {
    return {
      code: {
        loading: false,
        data: "",
        step: 60,
        interval: null
      },
      form: {
        codeLoading: false,
        data: {
          account: '',  // 15927563994, 13528794191, 18507085907
          password: '',  // 123456
          code: '',
        },
        rules: {
          account: [{ required: true, message: '请输入账号', trigger: ['blur','change'] }],
          code: [{ required: true, message: '请输入验证码', trigger: ['blur','change'] }],
        }
      },
    }
  },
  methods: {
    setCodeTime() {
      this.code.interval = setInterval(() => {
        if (this.code.step > 1) {
          this.code.step --
        } else {
          clearInterval(this.code.interval)
          this.code.interval = null
          this.code.step = 60
        }
      }, 1000)
    },
    // 获取验证码
    getCode() {
      if(!this.form.data.account) {
        return this.$message.warning('请输入账号')
      }
      this.form.codeLoading = true
      this.$http.post('user/flex_login/sendSms', {type:1,account:this.form.data.account}).then(res => {
        if(res.code == 1) {
          this.setCodeTime()
        }
      }).finally(() => {
        this.form.codeLoading = false
      })
    },
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          let _params = {
            account: this.form.data.account,
            code: this.form.data.code,
          };
          this.$http.post('/user/flex_login/login', _params).then(res => {
            if(res.code == 1) {
              setStorage('token', res.data.token)
              setStorage('account', res.data.account)
              this.$router.push('/order')
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.flash-hover {
	position: relative;
	overflow: hidden
}
.flash-hover:hover::after {
	left: 100%
}
.flash-hover::after {
	content: "";
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	display: block;
	height: 100%;
	background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#fff), to(transparent));
	background: linear-gradient(90deg, transparent, #fff, transparent);
	-webkit-transition: .5s;
	transition: .5s
}
.login__wrap {
  width: 100%;
  height: 100vh;

  .login__head {
    height: 65px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 60px;
  }

  .login__bg {
    // height: calc(100vh - 65px);
    height: 574px;
    background-image: url('~@/assets/img/bg.png');
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login__main {
    margin-left: 40%;

    .login__box {
      width: 400px;
      border-radius: 5px;
      background-color: #fffefe;
      box-sizing: border-box;
      overflow: hidden;
      border: solid 1px #eee;
      margin-top: 30px;
    }
    .sign__main {
      margin: 40px 32px;
    }
    .title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px;
      color: #595959;
    }

    .theneBtn {
      background-color:#1966FF;
      border-color: #1966FF;
      color: #fff;
      width: 100%;
      padding: 15px 40px;
      font-size:16px;
    }
    .rule {
      background: #F9F9FA;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
    }
    .rule a {
      color: #1966FF;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
.foot__wrap {
  background: #F3F4F6;
  height: 240px;
  padding: 52px 0 49px;

  .page-width {
    width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.group_13 {
  width: 697px;
}
.space-y-16 > *:not(:first-child) {
  margin-top: 0px;
}
.space-x-38 > *:not(:first-child) {
  margin-left: 38px;
}
.image_8 {
  width: 154px;
  height: 40px;
}
.text_12 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 38px;
  color: #AAACB1;
}
.group_12 {
  margin-right: 6px;
}
.space-x-44 > *:not(:first-child) {
  margin-left: 44px;
}
.code-box {
  background-color: #fff;
  width: 106px;
  height: 106px;
}
.group_14 {
  padding-left: 191px;
  height: 25px;
  margin-top: 22px;
}
.group_15 {
  margin-top: -14px;
}
.space-x-34-reverse > *:not(:last-child) {
  margin-right: 49px;
}
.text_30 {
  margin: 0 8px;
  line-height: 16px;
}
.font_14 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 17px;
  color: #878A8E;
}
.copyright {
  margin-left: 20px;
}
</style>
